/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Layout from 'components/Layout'
import ApplyToday from 'components/careers/ApplyToday'
import PressBoxes from 'components/press/PressBoxes'

import { getImageDataByFilename } from '../utils/getImageDataByFilename'
import Seo from '../components/Seo'

type companyStat = {
  title: string
  detail: string | JSX.Element
}

const companyStats: companyStat[] = [
  {
    title: 'Founded',
    detail: '2016',
  },
  {
    title: 'Headquarters',
    detail: 'Toronto',
  },
  {
    title: 'People',
    detail: (
      <>
        190<span className="company-stats__details--sm">+</span>
      </>
    ),
  },
  {
    title: 'Funding (US$)',
    detail: '275M',
  },
]

type supporter = {
  title: string
  image: string | unknown
}

const supportersArray: supporter[] = [
  {
    title: 'Georgian',
    image: 'georgian.png',
  },
  {
    title: 'Bessemer Venture Partners',
    image: 'bessemer.png',
  },
  {
    title: 'OMERS',
    image: 'omers.png',
  },
  {
    title: 'Capricorn Investment Group',
    image: 'capricorn.png',
  },
  {
    title: 'Tiger Global',
    image: 'tiger_global.png',
  },
  {
    title: 'Draper Associates',
    image: 'draper_associates.png',
  },
  {
    title: 'BDC',
    image: 'bdc.png',
  },
  {
    title: 'In-Q-Tel',
    image: 'iqt.png',
  },
  {
    title: 'Golden Ventures',
    image: 'gloden_ventures.png',
  },
  {
    title: 'Real Ventures',
    image: 'real.png',
  },
  {
    title: 'Radical Ventures',
    image: 'radical_ventures.png',
  },
  {
    title: 'Pegasus',
    image: 'pegasus.png',
  },
  {
    title: 'Alumni Ventures',
    image: 'Alumni_Ventures.png',
  },
  {
    title: 'Forward Investments',
    image: 'Forward_Investments.png',
  },
  {
    title: 'Porsche SE',
    image: 'Porsche_SE.png',
  },
]

const About = () => {
  const data = useStaticQuery(query)

  const articles = data.allMdx.nodes.filter(
    (node: { fields: { collection: string } }) =>
      node.fields.collection === 'press'
  )

  return (
    <Layout>
      {/* HERO */}
      <div className="bg-company-quote">
        <div className="py-32 inner-container">
          <div className="flex flex-col justify-center w-full h-full lg:w-1/2">
            <div className="flex-none text-white">
              <p className="text-white subtitle opacity-70">// Mission</p>
              <h1 className="mt-4 h2">
                To build quantum computers that are useful and available to
                people everywhere
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* STATS */}
      <div className="standard-page-section">
        <div className="flex flex-row flex-wrap -mx-3 -my-4 inner-container justify-center">
          {companyStats.map(({ title, detail }, index) => {
            return (
              <div className="w-1/2 px-3 py-4 md:w-1/3 xl:w-1/6" key={index}>
                <div className="font-semibold text-center">{title}</div>
                <div className="flex flex-row items-center justify-center font-mono font-semibold company-stats__details">
                  {detail}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* INVESTORS */}
      <div className="overflow-hidden bg-grey-300 lg:bg-transparent">
        <div className="inner-container rounded-5px lg:bg-grey-300 standard-page-section">
          <p className="text-center subtitle">// Investors</p>
          <div className="mt-13">
            <ul className="flex flex-wrap justify-center -mx-6 -my-10.5">
              {supportersArray.map(({ title, image }, index) => {
                return (
                  <li
                    className="px-6.25 py-10.5 flex flex-col justify-center"
                    style={{ maxWidth: '300px' }}
                    key={index}
                  >
                    <GatsbyImage
                      image={getImageDataByFilename(String(image))}
                      alt={title}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* PRESS */}
      <div className="overflow-hidden standard-page-section inner-container">
        <p className="subtitle">// Press</p>
        <h2 className="mt-2.5">Featured articles</h2>
        <div className="mt-20">
          <PressBoxes articles={articles.slice(0, 6)} />
        </div>
        <div className="mt-20 text-center">
          <Link to="/press" className="btn btn-lg btn-1">
            More articles
          </Link>
        </div>
      </div>

      {/* CAREERS */}
      {/* <CareerSlider /> */}
      <div className="inner-container standard-page-section">
        <div className="flex flex-wrap -m-1.5">
          <div className="w-full p-1.5 sm:w-1/2">
            <StaticImage
              src="../images/company/working_images/company_8.jpg"
              className="w-full h-full"
              alt="Working in the Xanadu office overlooking the Toronto skyline"
            />
          </div>
          <div className="w-full p-1.5 sm:w-1/2">
            <StaticImage
              src="../images/company/working_images/company_2.jpg"
              className="w-full h-full"
              alt="Working in the Xanadu lab, Toronto"
            />
          </div>
          <div className="w-full p-1.5 sm:w-1/2">
            <StaticImage
              src="../images/company/working_images/company_1.jpg"
              className="w-full h-full"
              alt="A demonstration in the Xanadu lab, Toronto"
            />
          </div>
          <div className="w-full p-1.5 sm:w-1/2">
            <StaticImage
              src="../images/company/working_images/company_9.jpg"
              className="w-full h-full"
              alt="Quantum computing chips"
            />
          </div>
        </div>
      </div>
      {/* APPLY TODAY */}
      <div className="inner-container standard-page-section">
        <ApplyToday />
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { featured: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          slug
        }
        fields {
          collection
        }
        id
        slug
        excerpt
      }
    }
  }
`

export default About

export const Head = () => {
  return (
    <Seo
      title="About"
      description="Take part in Xanadu's mission to build quantum computers that are useful and available to people everywhere"
      image="homepage.jpg"
    />
  )
}
